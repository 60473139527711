import React from 'react';
import { useTranslation } from 'react-i18next';

const NewsArticleList = () => {
  const { t, i18n } = useTranslation();
  
  const articles = [
    {
      id: 2,
      titleKey: "articles.red3.title",
      date: "2024-01-22",
      content: {
        introductionKey: "articles.red3.introduction",
        body: [
          {
            textKey: "articles.red3.body.main"
          },
          {
            textKey: "articles.red3.body.mission"
          }
        ],
        signatureKey: "articles.red3.signature",
        pdf: {
          en: "https://drive.google.com/file/d/1yCrs-YThs8PSlbHOZtifNwB_IGZR1Cae/view?usp=sharing",
          es: "https://drive.google.com/file/d/1t5s-hE0k1yZnhckabVMHhX8bNQN88MKP/view?usp=sharing"
        }
      }
    },
    {
      id: 1,
      titleKey: "articles.star3.title",
      date: "2024-10-17",
      content: {
        introductionKey: "articles.star3.introduction",
        body: [
          {
            textKey: "articles.star3.body.recognition"
          }
        ]
      },
      contact_information: {
        address: "30-32 West Broad Street, West Hazleton, PA 18202",
        phone: "570-455-0519",
        email: "contact@littleallstarscarecenter.com"
      }
    }
  ];

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(
      i18n.language === 'en' ? 'en-US' : 'es-ES',
      { year: 'numeric', month: 'long', day: 'numeric' }
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        {/* <h1 className="text-2xl font-bold">
          {t('common.newsArticles')}
        </h1> */}
      </div>

      <div className="grid gap-6 md:grid-cols-1">
        {articles.map((article) => (
          <div key={article.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-6">
              <div className="flex flex-col gap-2 justify-between items-start mb-4 border-b pb-2">
                <h2 className="text-2xl font-bold">
                  {t(article.titleKey)}
                </h2>
                <span className="text-sm text-gray-500">
                  {formatDate(article.date)}
                </span>
              </div>
              
              <div className="space-y-4">
                <p className="text-gray-700">
                  {t(article.content.introductionKey)}
                </p>
                
                {article.content.body.map((section, index) => (
                  <div key={index} className="mt-4">
                    <p className="text-gray-600">{t(section.textKey)}</p>
                  </div>
                ))}

                {article.content.signatureKey && (
                  <div className="mt-4 text-gray-700 italic">
                    {t(article.content.signatureKey).split('\n').map((line, i) => (
                      <p key={i}>{line}</p>
                    ))}
                  </div>
                )}

                {article.content.pdf && (
                  <div className="mt-6">
                    <a 
                      href={article.content.pdf[i18n.language]}
                      className="inline-block px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors font-medium"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('common.downloadReport')}
                    </a>
                  </div>
                )}

                {article.contact_information && (
                  <div className="mt-6 p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold mb-2">
                      {t('common.contactInfo')}
                    </h4>
                    <div className="text-gray-600">
                      <p>{article.contact_information.address}</p>
                      <p>{t('common.phone')}: {article.contact_information.phone}</p>
                      <p>Email: {article.contact_information.email}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsArticleList;