import React from 'react';
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection';
import PreinscriptionForm from '../components/PreinscriptionForm'; // Importa el componente del formulario

const Preinscription = () => {
  const { t } = useTranslation();
  
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('contact.title')}
            description={t('contact.description')}
          />
        </div>
      </section>
      
      {/* Formulario de Preinscripción */}
      <section>
        <PreinscriptionForm />
      </section>
    </div>
  );
};

export default Preinscription;