import React from 'react';
import { FaBookOpen, FaEye, FaHeart } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import GaleriaImagenes from '../components/GaleriaImagenes';
import HeroSection from '../components/HeroSection';

const About = () => {
  const { t } = useTranslation(); // Inicializa el hook de traducción

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('about.title')}
            description={t('about.description')}
          />
        </div>
      </section>

      {/* Misión, Visión y Valores */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-semibold text-sky-600 mb-12 text-center">{t('about.missionVisionValues.title')}</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { icon: <FaBookOpen className="text-4xl text-yellow-500 mb-4" />, title: t('about.missionVisionValues.missionTitle'), content: t('about.missionVisionValues.missionDescription') },
              { icon: <FaEye className="text-4xl text-yellow-500 mb-4" />, title: t('about.missionVisionValues.visionTitle'), content: t('about.missionVisionValues.visionDescription') },
              { icon: <FaHeart className="text-4xl text-yellow-500 mb-4" />, title: t('about.missionVisionValues.valuesTitle'), content: t('about.missionVisionValues.valuesDescription') },
            ].map((item, index) => (
              <div key={index} className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2">
                {item.icon}
                <h3 className="text-xl font-bold text-sky-600 mb-4">{item.title}</h3>
                <p className="text-gray-600">{item.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Nuestro Método Educativo */}
      <section className="bg-white py-16 border-t-2 border-b-2 border-t-green-500 border-b-sky-400">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-sky-600 mb-8 text-center">{t('about.educationMethod.title')}</h2>
          <p className="text-lg text-gray-600 mb-8 max-w-3xl mx-auto text-center">
            {t('about.educationMethod.description')}
          </p>
        </div>
      </section>

      {/* Sección de Video */}
      <section className="bg-gray-50 border-b shadow-sm py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-sky-600 mb-8">{t('about.videoSection.title')}</h2>
          <p className="text-lg text-gray-600 mb-4">
            {t('about.videoSection.description')}
          </p>
          <div className="flex justify-center">
            <video controls className="w-full max-w-2xl rounded-lg shadow-lg">
              <source src="/video.mp4" type="video/mp4" />
              {t('about.videoSection.videoNotSupported')}
            </video>
          </div>
        </div>
      </section>

      <section>
        <GaleriaImagenes />
      </section>

      {/* Equipo Profesional */}
    {/*   <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-sky-600 mb-12 text-center">{t('about.team.title')}</h2>
          <p className="text-lg text-gray-600 mb-24 max-w-3xl mx-auto text-center">
            {t('about.team.description')}
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { name: "María Rodríguez", role: t('about.team.roles.director') },
              { name: "Carlos Martínez", role: t('about.team.roles.teacher') },
              { name: "Laura Gómez", role: t('about.team.roles.assistant') },
            ].map((member, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2">
                <img src={`/api/placeholder/150/150`} alt={member.name} className="w-32 h-32 mx-auto -mt-24 shadow-md border-4 border-sky-600 rounded-full mb-4 object-cover bg-slate-100" />
                <h3 className="text-xl font-semibold text-gray-700 text-center">{member.name}</h3>
                <p className="text-gray-500 text-center">{member.role}</p>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default About;
