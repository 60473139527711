import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PricingList = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('infante');

  const priceData = [
    {
      value: "infante",
      prices: [
        { type: "full_time_5days", price: 350.00 },
        { type: "full_time_4days", price: 70.00, unit: true }
      ]
    },
    {
      value: "pequenos",
      prices: [
        { type: "full_time_5days", price: 300.00 },
        { type: "full_time_4days", price: 60.00, unit: true }
      ]
    },
    {
      value: "mayores",
      prices: [
        { type: "full_time_5days", price: 300.00 },
        { type: "full_time_4days", price: 60.00, unit: true }
      ]
    },
    {
      value: "preescolar",
      prices: [
        { type: "full_time_5days", price: 275.00 },
        { type: "full_time_4days", price: 55.00, unit: true }
      ]
    }
  ];

  return (
    <section className="py-16 bg-gray-50 border-t-2 border-b-2 border-t-green-400 border-b-sky-400">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-3xl md:text-4xl font-bold text-sky-800 mb-8 text-center pb-4">
            {t('prices.title')}
          </h1>

          <div className="w-full">
            <div className="grid grid-cols-4 bg-gray-50 rounded-lg p-1">
              {priceData.map((category) => (
                <button
                  key={category.value}
                  onClick={() => setActiveTab(category.value)}
                  className={`py-2 px-4 rounded-md transition-colors duration-200
                    ${activeTab === category.value 
                      ? 'bg-white text-sky-800 shadow-sm' 
                      : 'text-gray-600 hover:text-sky-600'}`}
                >
                  {t(`prices.categories.${category.value}.title`)}
                </button>
              ))}
            </div>

            <div className="mt-6">
              {priceData.map((category) => (
                <div
                  key={category.value}
                  className={`${activeTab === category.value ? 'block' : 'hidden'}`}
                >
                  <div className="bg-white p-8 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105 border-b-8 border-b-green-500">
                    <h2 className="text-xl font-semibold text-sky-800 mb-4">
                      {t(`prices.categories.${category.value}.title`)}
                      <span className="text-sm font-normal ml-2 text-gray-600">
                        ({t(`prices.categories.${category.value}.age`)})
                      </span>
                    </h2>
                    <div className="space-y-3">
                      {category.prices.map((price, idx) => (
                        <div key={idx} className="flex justify-between items-center">
                          <span className="text-gray-600">{t(`prices.types.${price.type}`)}</span>
                          <span className="font-medium text-sky-800">
                            ${price.price.toFixed(2)} USD {price.unit ? t('prices.per_day') : t('prices.per_week')}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-6 space-y-4">
              <div className="bg-gray-50 p-6 rounded-lg border-l-4 border-l-yellow-400">
                <p className="font-medium text-sky-800">
                  {t('prices.full_time_note.title')}
                </p>
                <p className="mt-2 text-gray-600">
                  {t('prices.full_time_note.description')}
                </p>
                <p className="mt-2 text-gray-600">
                  {t('prices.full_time_note.approval')}
                </p>
              </div>
              <p className="italic text-gray-600">
                {t('prices.changes_notice')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingList;