import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageSquare } from 'lucide-react';
import HeroSection from '../components/HeroSection';

const Contact = () => {
  const { t } = useTranslation();

  const handleChatbotClick = () => {
    window.open('https://ia.news724.online/chatbot/7b1c0cb0-2996-46fe-94a1-d261f9d1eabc', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('contact.title')}
            description={t('contact.description')}
          />
        </div>
      </section>

      <div className='container mx-auto px-4 py-12 max-w-6xl'>
        <div className='flex flex-col lg:flex-row gap-8 items-stretch'>
          {/* Sección de chatbot */}
          <section className="
            lg:w-1/2 p-8 bg-white rounded-2xl shadow-xl
            border border-gray-100
            flex flex-col items-center justify-center
            text-center
          ">
            <div className="
              w-20 h-20 bg-blue-500 rounded-full
              flex items-center justify-center
              mb-6
            ">
              <MessageSquare size={40} className="text-white" />
            </div>

            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              {t('contact.chatbotTitle')}
            </h2>

            <p className="text-gray-600 mb-8 max-w-md">
              {t('contact.chatbotDescription')}
            </p>

            <button
              onClick={handleChatbotClick}
              className="
                group
                relative
                inline-flex items-center justify-center
                px-8 py-4
                bg-blue-500 hover:bg-blue-600
                text-white font-medium
                rounded-full
                transition-all duration-300
                shadow-lg hover:shadow-xl
                overflow-hidden
              "
            >
              <span className="relative flex items-center">
                <MessageSquare size={20} className="mr-2" />
                {t('contact.startChatButton')}
              </span>
            </button>
          </section>

          {/* Datos de contacto */}
          <section className="
            lg:w-1/2
            p-8 bg-white rounded-2xl shadow-xl
            border border-gray-100
          ">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              {t('contact.infoTitle')}
            </h2>

            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="font-medium text-gray-800 mb-1">
                  {t('contact.infoOrganization')}
                </p>
                <p className="text-gray-600">
                  {t('contact.infoAddress')}
                </p>
                <p className="text-gray-600">
                  <a 
                    href="tel:570-455-0519" 
                    className="text-blue-500 hover:underline"
                  >
                    570-455-0519
                  </a>
                </p>
                <p className="text-gray-600">
                  <a 
                    href="mailto:contact@littleallstarscarecenter.com" 
                    className="text-blue-500 hover:underline"
                  >
                    contact@littleallstarscarecenter.com
                  </a>
                </p>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="font-medium text-gray-800 mb-1">
                  {t('contact.businessHours')}
                </p>
                <p className="text-gray-600">
                  {t('contact.businessHoursDetail')}
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;