/* eslint-disable no-unused-vars */
import React from 'react';
import { FaChalkboardTeacher, FaUsers, FaAppleAlt, FaClipboardList, FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import HeroSection from '../components/HeroSection';
import { MessageSquare } from 'lucide-react';

const jobs = [
  {
    icon: <FaExternalLinkAlt className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.visitJobPortal.title",
    descriptionKey: "jobs.visitJobPortal.description",
    link: "https://jobs.littleallstarscarecenter.com/",
    backgroundColor: "#f3f4f6", // Fondo gris claro
    borderColor: "#e5e7eb" // Borde gris claro
  },
  {
    icon: <FaChalkboardTeacher className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.preschoolTeachers.title",
    descriptionKey: "jobs.preschoolTeachers.description",
    backgroundColor: "#fff7ed", // Fondo naranja claro
    borderColor: "#fde68a" // Borde amarillo claro
  },
  {
    icon: <FaUsers className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.teacherAssistants.title",
    descriptionKey: "jobs.teacherAssistants.description",
    backgroundColor: "#eff6ff", // Fondo azul claro
    borderColor: "#93c5fd" // Borde azul claro
  },
  {
    icon: <FaAppleAlt className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.kitchenStaff.title",
    descriptionKey: "jobs.kitchenStaff.description",
    backgroundColor: "#fef3c7", // Fondo amarillo claro
    borderColor: "#fde047" // Borde amarillo brillante
  },
  {
    icon: <FaClipboardList className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.administrativeStaff.title",
    descriptionKey: "jobs.administrativeStaff.description",
    backgroundColor: "#ecfccb", // Fondo verde claro
    borderColor: "#bef264" // Borde verde brillante
  }
];

const JobOpenings = () => {
  const { t } = useTranslation(); // Inicializa el hook de traducción

  const handleJobPortalClick = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('jobs.title')}
            description={t('jobs.description')}
          />
        </div>
      </section>

      {/* Vacantes disponibles */}
      <section className="py-16">
        <div className="container mx-auto px-4 grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {jobs.map((job, index) => (
            <div
              key={index}
              className="p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl"
              onClick={() => job.link && handleJobPortalClick(job.link)}
              style={{
                cursor: job.link ? 'pointer' : 'default',
                backgroundColor: job.backgroundColor,
                border: `1px solid ${job.borderColor}`
              }}
            >
              {job.icon}
              <h2 className="text-2xl font-semibold text-sky-600 mb-4">{t(job.titleKey)}</h2>
              <p className="text-gray-600">{t(job.descriptionKey)}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Sección de chatbot */}
      <section className="
            lg:w--full p-8 bg-white shadow-xl
            border border-gray-100
            flex flex-col items-center justify-center
            text-center
          ">
        <div className="
              w-20 h-20 bg-blue-500 rounded-full
              flex items-center justify-center
              mb-6
            ">
          <MessageSquare size={40} className="text-white" />
        </div>

        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          {t('contact.chatbotTitle')}
        </h2>

        <p className="text-gray-600 mb-8 max-w-md">
          {t('contact.chatbotDescription')}
        </p>

        <button
          onClick={() => window.open('https://ia.news724.online/chatbot/7b1c0cb0-2996-46fe-94a1-d261f9d1eabc', '_blank', 'noopener,noreferrer')}
          className="
                group
                relative
                inline-flex items-center justify-center
                px-8 py-4
                bg-blue-500 hover:bg-blue-600
                text-white font-medium
                rounded-full
                transition-all duration-300
                shadow-lg hover:shadow-xl
                overflow-hidden
              "
        >
          <span className="relative flex items-center">
            <MessageSquare size={20} className="mr-2" />
            {t('contact.startChatButton')}
          </span>
        </button>
      </section>

    </div>
  );
};

export default JobOpenings;
