import React from 'react';
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection';
import NewsArticleList from '../components/NewsArticleList';

const Blog = () => {
  const { t } = useTranslation();


  return (
    <div className="bg-gray-50 ">

      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('blog.title')}
            description={t('blog.heroText')}
          />
        </div>
      </section>
      <section>
        <NewsArticleList/>
      </section>


    </div>
  );
};

export default Blog;
