// PreinscriptionForm.jsx
import { useState } from 'react';
import {
  FormInput,
  FormSelect,
  FormSection,
  FormCheckbox,
  ParentInfoForm
} from './FormComponents';


// Componente principal del formulario
const PreinscriptionForm = () => {
  const [formData, setFormData] = useState({
    // Información del niño
    childName: '',
    birthDate: '',
    gender: '',
    medicalConditions: '',

    // Información de los padres
    parent1Name: '',
    parent1Id: '',
    parent1Mobile: '',
    parent1Phone: '',
    parent1Email: '',
    parent1Address: '',
    parent1Occupation: '',
    parent1Schedule: '',

    parent2Name: '',
    parent2Id: '',
    parent2Mobile: '',
    parent2Phone: '',
    parent2Email: '',
    parent2Address: '',
    parent2Occupation: '',
    parent2Schedule: '',

    // Información de inscripción
    startDate: '',
    schedule: '',

    // Documentos
    dniParents: false,
    birthCertificate: false,
    vaccineCard: false,

    // Términos
    termsAccepted: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Aquí iría la lógica para enviar los datos
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Formulario de Preinscripción - Lista de Espera
        </h1>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Sección Información del Niño */}
          <FormSection title="1. Información del Niño/a">
            <div className="grid md:grid-cols-2 gap-6">
              <FormInput 
                label="Nombre completo"
                type="text"
                name="childName"
                value={formData.childName}
                required
                onChange={handleChange}
              />
              <FormInput 
                label="Fecha de nacimiento"
                type="date"
                name="birthDate"
                value={formData.birthDate}
                required
                onChange={handleChange}
              />
              <FormSelect 
                label="Género"
                name="gender"
                value={formData.gender}
                options={[
                  { value: 'masculino', label: 'Masculino' },
                  { value: 'femenino', label: 'Femenino' },
                  { value: 'otro', label: 'Otro' }
                ]}
                onChange={handleChange}
              />
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Alergias o condiciones médicas
                </label>
                <textarea
                  name="medicalConditions"
                  value={formData.medicalConditions}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              </div>
            </div>
          </FormSection>

          {/* Sección Información de los Padres */}
          <FormSection title="2. Información de los Padres/Tutores">
            <ParentInfoForm parentNumber={1} onChange={handleChange} />
            <ParentInfoForm parentNumber={2} onChange={handleChange} />
          </FormSection>

          {/* Sección Información de Inscripción */}
          <FormSection title="3. Información de Inscripción">
            <div className="grid md:grid-cols-2 gap-6">
              <FormInput 
                label="Fecha deseada de inicio"
                type="date"
                name="startDate"
                value={formData.startDate}
                required
                onChange={handleChange}
              />
              <FormSelect 
                label="Horario requerido"
                name="schedule"
                value={formData.schedule}
                required
                options={[
                  { value: 'completo', label: 'Tiempo completo' },
                  { value: 'medio', label: 'Medio tiempo' }
                ]}
                onChange={handleChange}
              />
            </div>
          </FormSection>

          {/* Sección Documentos */}
          <FormSection title="4. Documentos Requeridos">
            <div className="space-y-3">
              <FormCheckbox 
                label="Copia DNI padres"
                name="dniParents"
                checked={formData.dniParents}
                onChange={handleChange}
              />
              <FormCheckbox 
                label="Copia partida nacimiento"
                name="birthCertificate"
                checked={formData.birthCertificate}
                onChange={handleChange}
              />
              <FormCheckbox 
                label="Cartilla de vacunación"
                name="vaccineCard"
                checked={formData.vaccineCard}
                onChange={handleChange}
              />
            </div>
          </FormSection>

          {/* Términos y Condiciones */}
          <FormCheckbox 
            label="Acepto los términos y condiciones *"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleChange}
            required
          />

          {/* Botón de Envío */}
          <div className="pt-6">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Enviar Solicitud
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PreinscriptionForm;