// src/i18n/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Definir las traducciones en diferentes idiomas
const texts = {
  es: {
    translation: {
      common: {
        newsArticles: "Artículos de Noticias",
        contactInfo: "Información de Contacto",
        phone: "Teléfono",
        downloadReport: "Descargar Reporte Completo",
      },
      articles: {
        star3: {
          title:
            "Little All Stars Care Center alcanza la Estrella 3 en el programa Keystone STARS",
          introduction:
            "En Little All Stars Care Center, ubicado en 30-32 West Broad Street, West Hazleton, PA 18202, estamos encantados de compartir una noticia emocionante: ¡hemos alcanzado la Estrella 3 en el prestigioso programa Keystone STARS!",
          body: {
            recognition:
              "Este reconocimiento es un testimonio de nuestro compromiso continuo con brindar un cuidado infantil de la más alta calidad. La Estrella 3 refleja nuestros esfuerzos para ofrecer un entorno seguro, educativo y enriquecedor para los niños que confían en nosotros. No solo cumplimos con los estándares básicos de cuidado infantil, sino que vamos más allá, asegurando que cada niño reciba una atención personalizada y un aprendizaje integral que fomente su desarrollo académico y social.",
          },
        },
        red3: {
          title: "Información Importante: Colorante RED 3 (Rojo 3)",
          introduction:
            "Saludos querida familia de Little All Stars Care Center",
          body: {
            main: "Recientemente ha salido a la luz pública un informe de la FDA, resaltando el peligro del uso del colorante RED 3 (Rojo 3). Es por eso que hemos querido hacer un pequeño artículo sobre el peligro de ese colorante en nuestra cocina y también en algunos cosméticos para que ustedes como padres de familia se mantengan vigilantes de lo que le dan a comer a los niños.",
            mission:
              "La misión de nuestro Centro es estar vigilante a todo lo que rodea a nuestra comunidad para así poder informarles a ustedes.",
          },
          signature: "Bendecido día para todos\nIvelisse Eufracio-Childs",
        },
      },
      home: {
        title: "Bienvenidos a Little All Stars Care Center",
        description:
          "El lugar donde el cuidado y el desarrollo de su hijo son nuestra máxima prioridad. Desde que abrimos nuestras puertas, hemos proporcionado un ambiente seguro y acogedor para que los niños crezcan, aprendan y exploren. Descubra más sobre nuestros servicios, eventos y actividades diseñadas para el desarrollo integral de los niños. ¡Únase a nuestra comunidad hoy mismo y siéntase parte de nuestra familia!",
        sections: {
          title: "Nuestros valores",
          safety_title: "Seguridad Ante Todo: ",
          safety:
            "Implementamos estrictos protocolos de seguridad para garantizar que sus hijos estén siempre protegidos",
          education_title: "Educación y Diversión: ",
          education:
            "Nuestras actividades combinan el aprendizaje y el juego para una experiencia educativa completa",
          community_title: "Comunidad Inclusiva:",
          community:
            "Fomentamos un ambiente de respeto e inclusión donde todos los niños se sienten valorados",
        },
        callsToAction: {
          visitUs: "Inscríbase Ahora",
          enrollNow: "Únete al equipo",
          followUs: "Síganos en Redes Sociales:",
        },
        login: {
          title: "Área de Login",
          description:
            "Accede a tu cuenta para gestionar información y recibir actualizaciones.",
          usernamePlaceholder: "Usuario",
          passwordPlaceholder: "Contraseña",
          button: "Iniciar Sesión",
        },
        map: {
          title: "Ubicación",
        },
        gallery: {
          title: "Galería de Imágenes",
        },
      },
      navbar: {
        home: "Inicio",
        about: "Nosotros",
        services: "Servicios",
        jobs: "Convocatorias",
        activities: "Actividades",
        blog: "Blog",
        testimonials: "Testimonios",
        contact: "Contacto",
      },
      footer: {
        description:
          "Cuidado y educación de calidad para niños de 6 semanas a 5 años",
        contactTitle: "Contacto",
        phone: "Teléfono",
        email: "Correo electrónico",
        quickLinks: "Enlaces rápidos",
        aboutUs: "Sobre nosotros",
        services: "Servicios",
        activities: "Actividades",
        contactUs: "Contáctanos",
        hours: "Horario: ",
        schedule: "Lunes a Viernes, 6:30 AM - 7:00 PM",
        rightsReserved: "Todos los derechos reservados.",
      },
      about: {
        title: "Nosotros",
        description:
          "En Little All Stars Care Center, estamos comprometidos con el desarrollo integral de cada niño que ingresa a nuestras instalaciones. Nuestro centro ha crecido, gracias a la confianza y el apoyo de la comunidad. Con un enfoque centrado en el niño, ofrecemos experiencias educativas y lúdicas que promueven el bienestar físico, emocional y social.",
        learnMore: "Conocer Más",
        missionVisionValues: {
          title: "Misión, Visión y Valores",
          missionTitle: "Misión",
          missionDescription:
            "Proveer un entorno seguro, afectuoso y estimulante donde los niños puedan desarrollarse física, emocional, social y cognitivamente.",
          visionTitle: "Visión",
          visionDescription:
            "Ser reconocidos como una guardería líder destacada por la excelencia en el cuidado y la educación infantil.",
          valuesTitle: "Valores",
          valuesDescription:
            "Seguridad, respeto, inclusión, creatividad y desarrollo integral.",
        },
        educationMethod: {
          title: "Nuestro Método Educativo",
          description:
            "Nuestro método educativo se basa en los principios de la pedagogía activa, donde los niños aprenden a través del juego, la exploración y la interacción social.",
        },
        videoSection: {
          title: "Mira Nuestro Video",
          description:
            "Descubre más sobre nuestras instalaciones y el ambiente acogedor que ofrecemos a los niños.",
          videoNotSupported: "Tu navegador no soporta la etiqueta de video.",
        },
        team: {
          title: "Equipo Profesional",
          description:
            "Nuestro equipo de profesionales altamente calificados está apasionado por la educación infantil.",
          roles: {
            director: "Directora de Educación",
            teacher: "Profesor de Preescolar",
            assistant: "Asistente Educativa",
          },
        },
      },
      services: {
        title: "Nuestros Servicios",
        description:
          "En Little All Stars Care Center, ofrecemos una gama completa de servicios diseñados para apoyar el desarrollo integral de su hijo.",
        childCare: {
          title: "Cuidado Infantil",
          description:
            "Proporcionamos un ambiente seguro y afectuoso para el cuidado diario de los niños, con grupos reducidos para asegurar una atención personalizada.",
        },
        educationalActivities: {
          title: "Actividades Educativas",
          description:
            "Nuestras actividades están diseñadas para estimular el aprendizaje a través del juego, la creatividad y la exploración, incluyendo artes y manualidades, música y juegos educativos.",
        },
        earlyDevelopment: {
          title: "Desarrollo Temprano",
          description:
            "Implementamos programas que promueven el desarrollo físico, emocional y cognitivo de los niños, adaptados a cada etapa de crecimiento.",
        },
        nutritiousMeals: {
          title: "Comidas y Snacks Nutritivos",
          description:
            "Ofrecemos menús balanceados y nutritivos, preparados diariamente por nuestro personal de cocina, asegurando que los niños reciban la alimentación necesaria para su desarrollo.",
        },
        parentSupport: {
          title: "Apoyo a Padres",
          description:
            "Organizamos talleres y charlas para padres sobre temas relacionados con la crianza y el desarrollo infantil, creando una comunidad de apoyo mutuo.",
        },
        callToAction: {
          title: "¿Listo para comenzar?",
          description:
            "Contáctenos hoy mismo para programar una visita y descubrir cómo Little All Stars puede ser el lugar perfecto para el cuidado y desarrollo de su hijo.",
          buttonText: "Programar una Visita",
        },
      },
      jobs: {
        title: "Convocatorias Laborales",
        description:
          "Únase a nuestro equipo y forme parte de un ambiente de trabajo enriquecedor y dedicado al cuidado infantil. En Little All Stars Care Center, valoramos el compromiso y la pasión por la educación de nuestros empleados.",
        preschoolTeachers: {
          title: "Maestros de Preescolar",
          description:
            "Buscamos profesionales con experiencia en educación infantil y habilidades en la planificación y ejecución de actividades educativas.",
        },
        teacherAssistants: {
          title: "Asistentes de Maestros",
          description:
            "Apoyo en las actividades diarias del aula y cuidado de los niños, con oportunidades de desarrollo profesional continuo.",
        },
        kitchenStaff: {
          title: "Personal de Cocina",
          description:
            "Responsables de la preparación de comidas y snacks balanceados, siguiendo estrictas normas de higiene y nutrición.",
        },
        administrativeStaff: {
          title: "Personal Administrativo",
          description:
            "Gestión de tareas administrativas y atención a padres de familia, asegurando un funcionamiento eficiente de la guardería.",
        },
        applicationForm: {
          title: "Formulario de Aplicación",
          name: "Nombre",
          namePlaceholder: "Ingrese su nombre",
          email: "Correo Electrónico",
          emailPlaceholder: "Ingrese su correo electrónico",
          submit: "Enviar Solicitud",
        },
        callToAction: {
          title: "¿Listo para comenzar?",
          description:
            "Contáctenos hoy mismo para programar una visita y descubrir cómo Little All Stars puede ser el lugar perfecto para el cuidado y desarrollo de su hijo.",
          scheduleVisit: "Programar una Visita",
        },
        visitJobPortal: {
          title: "Visitar Portal de Empleos",
          description:
            "Explora todas las vacantes disponibles y aplica en línea a través de nuestro portal de empleos.",
        },
      },
      contact: {
        title: "Contáctanos",
        description:
          "Si tienes alguna pregunta, no dudes en contactarnos a través de nuestro correo electrónico o redes sociales.",
        chatbotTitle: "¿Necesitas ayuda?",
        chatbotDescription:
          "Nuestro asistente virtual está listo para responder tus preguntas y ayudarte con el proceso de inscripción.",
        startChatButton: "Iniciar Chat",
        infoText:
          "En Little All Stars Care Center, nos dedicamos al desarrollo integral de cada niño desde 2020. Ofrecemos experiencias educativas y lúdicas que promueven el bienestar físico, emocional y social de los niños.",
        infoTitle: "Datos de contacto",
        infoOrganization: "Little All Stars Care Center",
        infoAddress: "30-32 West Broad Street, West Hazleton, PA 18202",
        infoPhone: "570-455-0519",
        infoEmail: "contact@littleallstarscarecenter.com",
        businessHours: "Horario de Atención",
        businessHoursDetail: "Lunes a Viernes: 6:00 AM - 6:00 PM",
        mapTitle: "Nuestra Ubicación",
      },
      testimonials: {
        title: "Testimonios",
        description:
          "Escuchar la experiencia de nuestros padres es fundamental.",
        testimonialsList: [
          {
            text: "Desde que mi hijo comenzó en Little All Stars Care Center hemos visto un tremendo avance en su desarrollo. El personal es increíblemente atento y profesional.",
            author: "- Padre Satisfecho",
          },
          {
            text: "La experiencia de nuestra hija en Little All Stars Care Center ha sido maravillosa. Los programas educativos y el personal cariñoso superaron nuestras expectativas.",
            author: "- Madre Satisfecha",
          },
          {
            text: "No podríamos estar más felices con el progreso de nuestro hijo. La atención y el cuidado que recibe son excepcionales.",
            author: "- Madre Agradecida",
          },
          {
            text: "Little All Stars Care Center ha sido un gran apoyo para nuestra familia. Los niños se divierten mientras aprenden, ¡y eso es lo que más nos importa!",
            author: "- Padre Contento",
          },
        ],
        ctaTitle: "¿Listo para comenzar?",
        ctaDescription: "Contáctenos hoy mismo para programar una visita.",
        ctaButton: "Programar una Visita",
      },
      register: {
        title: "Registro",
        heroText:
          "Únete a nuestra comunidad y comienza a disfrutar de todas las actividades y beneficios que ofrecemos en Little All Stars Care Center.",
        familyInfo: "Información de la Familia",
        parentName: "Nombre del Padre/Madre",
        email: "Correo electrónico",
        phone: "Número de teléfono",
        address: "Dirección",
        childInfo: "Información del Niño",
        childName: "Nombre del Niño",
        childAge: "Edad del Niño",
        childBirthdate: "Fecha de Nacimiento del Niño",
        notes: "Notas adicionales",
        registerButton: "Registrarse",
      },
      blog: {
        title: "Blog",
        heroText:
          "Bienvenido a nuestro blog, donde compartimos consejos de crianza, novedades sobre nuestras actividades y artículos sobre el desarrollo infantil. Manténgase informado y conectado con nuestra comunidad.",
        readMore: "Leer más",
        subscribeTitle: "¿Quieres más consejos?",
        subscribeText:
          "Suscríbete a nuestro boletín para recibir las últimas actualizaciones y consejos directamente en tu correo electrónico.",
        subscribeButton: "Suscribirse",
      },
      activityCalendar: {
        title: "Calendario de Actividades",
        heroText:
          "En Little All Stars Care Center, creemos en la importancia de actividades variadas que fomenten el desarrollo integral de los niños. Aquí encontrará toda la información sobre nuestras próximas actividades.",
        subscribeTitle: "¡No te pierdas nuestras actividades!",
        subscribeText:
          "Inscríbete ahora para que tu hijo pueda disfrutar de todas las actividades que tenemos preparadas para él. ¡Esperamos verte pronto!",
        subscribeButton: "Inscribirse",
        activities: [
          {
            title: "Actividad de Manualidades",
            description:
              "Una tarde de creación artística usando diversos materiales reciclados.",
            date: "15 de octubre, 2024",
          },
          {
            title: "Día de Campo",
            description:
              "Un día al aire libre con juegos cooperativos y actividades deportivas.",
            date: "22 de octubre, 2024",
          },
          {
            title: "Taller de Música y Baile",
            description:
              "Una sesión interactiva para explorar ritmos y movimientos al compás de la música.",
            date: "29 de octubre, 2024",
          },
          {
            title: "Cuentacuentos",
            description:
              "Un momento especial para compartir historias y fomentar el amor por la lectura.",
            date: "5 de noviembre, 2024",
          },
        ],
      },
      prices: {
        title: "Lista de Precios",
        per_week: "por semana",
        per_day: "por día",
        categories: {
          infante: {
            title: "Infante",
            age: "1 año o menos",
          },
          pequenos: {
            title: "Niños pequeños",
            age: "13 a 24 meses",
          },
          mayores: {
            title: "Niños mayores",
            age: "2 a 3 años",
          },
          preescolar: {
            title: "Preescolar",
            age: "3 a 5 años",
          },
        },
        types: {
          full_time_5days: "Tiempo completo 5 días a la semana",
          full_time_4days: "Tiempo completo 4 días",
        },
        full_time_note: {
          title: "TIEMPO COMPLETO = de 5 a 9 horas",
          description:
            "Nota: Después de 9 horas, se cobrarán $15.00 USD por cada hora adicional, además de la tarifa normal.",
          approval:
            "Este tiempo adicional debe ser aprobado por adelantado para el director.",
        },
        changes_notice:
          "Cualquier cambio en esta lista de precios se comunicará dentro de una semana antes de que entre en vigor.",
      },
      preinscription: {
        title: "Formulario de Preinscripción - Lista de Espera",
        sections: {
          child: {
            title: "1. Información del Niño/a",
            fullName: "Nombre completo",
            birthDate: "Fecha de nacimiento",
            gender: "Género",
            medicalConditions: "Alergias o condiciones médicas",
            genderOptions: {
              male: "Masculino",
              female: "Femenino",
              other: "Otro",
            },
          },
          parents: {
            title: "2. Información de los Padres/Tutores",
            guardian: "Padre/Tutor",
            fullName: "Nombre completo",
            id: "DNI/Identificación",
            mobile: "Teléfono móvil",
            phone: "Teléfono fijo",
            email: "Email",
            address: "Dirección",
            occupation: "Ocupación",
            schedule: "Horario laboral",
          },
          enrollment: {
            title: "3. Información de Inscripción",
            startDate: "Fecha deseada de inicio",
            schedule: "Horario requerido",
            scheduleOptions: {
              fullTime: "Tiempo completo",
              partTime: "Medio tiempo",
            },
          },
          documents: {
            title: "4. Documentos Requeridos",
            parentId: "Copia DNI padres",
            birthCertificate: "Copia partida nacimiento",
            vaccineCard: "Cartilla de vacunación",
          },
        },
        terms: "Acepto los términos y condiciones",
        submit: "Enviar Solicitud",
        select: "Seleccionar",
      },
    },
  },
  en: {
    translation: {
      common: {
        newsArticles: "News Articles",
        contactInfo: "Contact Information",
        phone: "Phone",
        downloadReport: "Download Full Report",
      },
      articles: {
        star3: {
          title:
            "Little All Stars Care Center Achieves Star 3 in the Keystone STARS Program",
          introduction:
            "At Little All Stars Care Center, located at 30-32 West Broad Street, West Hazleton, PA 18202, we are excited to share some thrilling news: we have achieved Star 3 in the prestigious Keystone STARS program!",
          body: {
            recognition:
              "This recognition is a testament to our ongoing commitment to providing the highest quality childcare. Star 3 reflects our efforts to offer a safe, educational, and enriching environment for the children who trust us. We not only meet basic childcare standards but go beyond, ensuring each child receives personalized care and comprehensive learning that fosters academic and social development.",
          },
        },
        red3: {
          title: "Important Information: RED 3 Dye",
          introduction:
            "Greetings dear family of Little All Stars Care Center,",
          body: {
            main: "A report from the FDA has recently come to light, highlighting the danger of using the dye RED 3. That is why we wanted to write a short article about the danger of this dye in our kitchen and also in some cosmetics so that you as parents can remain vigilant about what you feed your children.",
            mission:
              "The mission of our Center is to be vigilant about everything that surrounds our community in order to inform you.",
          },
          signature: "Blessed day to all,\nIvelisse Eufracio-Childs/Director",
        },
      },
      home: {
        title: "Welcome to Little All Stars Care Center",
        description:
          "The place where your child's care and development are our top priority. Since we opened our doors, we have provided a safe and nurturing environment for children to grow, learn and explore. Find out more about our services, events and activities designed for the integral development of children. Join our community today and be part of our family!",
        sections: {
          title: "Our Values",
          safety_title: "Safety First:",
          safety:
            "We implement strict safety protocols to ensure your children are always protected.",
          education_title: "Education and Fun:",
          education:
            "Our activities combine learning and play for a comprehensive educational experience",
          community_title: "Inclusive Community:",
          community:
            "We foster an environment of respect and inclusion where all children feel valued",
        },
        callsToAction: {
          visitUs: "Enroll Now",
          enrollNow: "Join the Team",
          followUs: "Follow us on Social Networks:",
        },
        login: {
          title: "Login Area",
          description:
            "Access your account to manage information and receive updates.",
          usernamePlaceholder: "User",
          passwordPlaceholder: "Password",
          button: "Start Session",
        },
        map: {
          title: "Location",
        },
        gallery: {
          title: "Image Gallery",
        },
      },
      navbar: {
        home: "Home",
        about: "About",
        services: "Services",
        jobs: "Jobs",
        activities: "Activities",
        blog: "Blog",
        testimonials: "Testimonials",
        contact: "Contact",
      },
      footer: {
        description:
          "Quality care and education for children of 6 weeks to 5 years",
        contactTitle: "Contact",
        phone: "Phone",
        email: "Email",
        quickLinks: "Quick Links",
        aboutUs: "About Us",
        services: "Services",
        activities: "Activities",
        contactUs: "Contact Us",
        hours: "Hours:",
        schedule: "Monday to Friday, 6:30 AM - 7:00 PM",
        rightsReserved: "All rights reserved.",
      },
      about: {
        title: "About Us",
        description:
          "At Little All Stars Care Center, we are committed to the comprehensive development of every child who enters our facility. Our nursery has grown, thanks to the trust and support of the community. With a child-centered approach, we offer educational and playful experiences that promote physical, emotional and social well-being.",
        learnMore: "Learn More",
        missionVisionValues: {
          title: "Mission, Vision, and Values",
          missionTitle: "Mission",
          missionDescription:
            "Provide a safe, nurturing, and stimulating environment where children can develop physically, emotionally, socially, and cognitively.",
          visionTitle: "Vision",
          visionDescription:
            "To be recognized as a leading daycare known for excellence in child care and education.",
          valuesTitle: "Values",
          valuesDescription:
            "Safety, respect, inclusion, creativity, and holistic development.",
        },
        educationMethod: {
          title: "Our Educational Method",
          description:
            "Our educational method is based on active pedagogy principles, where children learn through play, exploration, and social interaction.",
        },
        videoSection: {
          title: "Watch Our Video",
          description:
            "Discover more about our facility and the welcoming environment we offer children.",
          videoNotSupported: "Your browser does not support the video tag.",
        },
        team: {
          title: "Professional Team",
          description:
            "Our highly qualified professional team is passionate about early childhood education.",
          roles: {
            director: "Director of Education",
            teacher: "Preschool Teacher",
            assistant: "Educational Assistant",
          },
        },
      },
      services: {
        title: "Our Services",
        description:
          "At {{strong}}, we offer a comprehensive range of services designed to support your child's holistic development.",
        childCare: {
          title: "Child Care",
          description:
            "We provide a safe and nurturing environment for daily childcare, with small groups to ensure personalized attention.",
        },
        educationalActivities: {
          title: "Educational Activities",
          description:
            "Our activities are designed to stimulate learning through play, creativity, and exploration, including arts and crafts, music, and educational games.",
        },
        earlyDevelopment: {
          title: "Early Development",
          description:
            "We implement programs that promote the physical, emotional, and cognitive development of children, tailored to each stage of growth.",
        },
        nutritiousMeals: {
          title: "Nutritious Meals and Snacks",
          description:
            "We offer balanced and nutritious menus, prepared daily by our kitchen staff, ensuring that children receive the necessary nutrition for their development.",
        },
        parentSupport: {
          title: "Parent Support",
          description:
            "We organize workshops and talks for parents on topics related to parenting and child development, creating a community of mutual support.",
        },
        callToAction: {
          title: "Ready to Get Started?",
          description:
            "Contact us today to schedule a visit and discover how Little All Stars can be the perfect place for your child's care and development.",
          buttonText: "Schedule a Visit",
        },
      },
      jobs: {
        title: "Job Openings",
        description:
          "Join our team and be part of a nurturing work environment dedicated to childcare. At Little All Stars Care Center, we value the commitment and passion of our employees for education.",
        preschoolTeachers: {
          title: "Preschool Teachers",
          description:
            "We are looking for prGalería de Imágenesofessionals with experience in early childhood education and skills in planning and executing educational activities.",
        },
        teacherAssistants: {
          title: "Teacher Assistants",
          description:
            "Support in daily classroom activities and child care, with opportunities for ongoing professional development.",
        },
        kitchenStaff: {
          title: "Kitchen Staff",
          description:
            "Responsible for preparing balanced meals and snacks, following strict hygiene and nutrition standards.",
        },
        administrativeStaff: {
          title: "Administrative Staff",
          description:
            "Management of administrative tasks and communication with parents, ensuring the efficient operation of the daycare.",
        },
        visitJobPortal: {
          title: "Visit Job Portal",
          description:
            "Explore all available job openings and apply online through our job portal.",
        },
        applicationForm: {
          title: "Application Form",
          name: "Name",
          namePlaceholder: "Enter your name",
          email: "Email",
          emailPlaceholder: "Enter your email",
          submit: "Submit Application",
        },
        callToAction: {
          title: "Ready to get started?",
          description:
            "Contact us today to schedule a visit and discover how Little All Stars can be the perfect place for your child's care and development.",
          scheduleVisit: "Schedule a Visit",
        },
      },
      contact: {
        title: "Contact Us",
        description:
          "If you have any questions, don't hesitate to contact us through our email or social media.",
        chatbotTitle: "Need Help?",
        chatbotDescription:
          "Our virtual assistant is ready to answer your questions and help you with the enrollment process.",
        startChatButton: "Start Chat",
        infoText:
          "At Little All Stars Care Center, we have been dedicated to the comprehensive development of each child since 2020. We offer educational and recreational experiences that promote children's physical, emotional, and social well-being.",
        infoTitle: "Contact Information",
        infoOrganization: "Little All Stars Care Center",
        infoAddress: "30-32 West Broad Street, West Hazleton, PA 18202",
        infoPhone: "570-455-0519",
        infoEmail: "contact@littleallstarscarecenter.com",
        businessHours: "Business Hours",
        businessHoursDetail: "Monday to Friday: 6:00 AM - 6:00 PM",
        mapTitle: "Our Location",
      },
      testimonials: {
        title: "Testimonials",
        description: "Hearing our parents' experiences is essential.",
        testimonialsList: [
          {
            text: "Since my son started at Little All Stars Care Center, we have seen tremendous progress in his development. The staff is incredibly attentive and professional.",
            author: "- Satisfied Father",
          },
          {
            text: "Our daughter's experience at Little All Stars Care Center has been wonderful. The educational programs and caring staff exceeded our expectations.",
            author: "- Satisfied Mother",
          },
          {
            text: "We couldn't be happier with our son's progress. The care and attention he receives are exceptional.",
            author: "- Grateful Mother",
          },
          {
            text: "Little All Stars Care Center has been a great support for our family. The children have fun while learning, and that's what matters most to us!",
            author: "- Happy Father",
          },
        ],
        ctaTitle: "Ready to get started?",
        ctaDescription: "Contact us today to schedule a visit.",
        ctaButton: "Schedule a Visit",
      },
      register: {
        title: "Register",
        heroText:
          "Join our community and start enjoying all the activities and benefits we offer at Little All Stars Care Center.",
        familyInfo: "Family Information",
        parentName: "Parent's Name",
        email: "Email",
        phone: "Phone Number",
        address: "Address",
        childInfo: "Child Information",
        childName: "Child's Name",
        childAge: "Child's Age",
        childBirthdate: "Child's Birthdate",
        notes: "Additional Notes",
        registerButton: "Register",
      },
      blog: {
        title: "Blog",
        heroText:
          "Welcome to our blog, where we share parenting tips, updates on our activities, and articles about child development. Stay informed and connected with our community.",
        readMore: "Read more",
        subscribeTitle: "Want more tips?",
        subscribeText:
          "Subscribe to our newsletter to receive the latest updates and tips directly in your inbox.",
        subscribeButton: "Subscribe",
      },
      activityCalendar: {
        title: "Activity Calendar",
        heroText:
          "At Little All Stars Care Center, we believe in the importance of diverse activities that promote children's holistic development. Here you will find all the information about our upcoming activities.",
        subscribeTitle: "Don't miss our activities!",
        subscribeText:
          "Sign up now so your child can enjoy all the activities we have prepared for them. We hope to see you soon!",
        subscribeButton: "Sign up",
        activities: [
          {
            title: "Craft Activity",
            description:
              "An afternoon of artistic creation using various recycled materials.",
            date: "October 15, 2024",
          },
          {
            title: "Field Day",
            description:
              "A day outdoors with cooperative games and sports activities.",
            date: "October 22, 2024",
          },
          {
            title: "Music and Dance Workshop",
            description:
              "An interactive session to explore rhythms and movements to the beat of the music.",
            date: "October 29, 2024",
          },
          {
            title: "Storytelling",
            description:
              "A special time to share stories and foster a love of reading.",
            date: "November 5, 2024",
          },
        ],
      },
      prices: {
        title: "Price List",
        per_week: "per week",
        per_day: "per day",
        categories: {
          infante: {
            title: "Infants",
            age: "1 Year and Under",
          },
          pequenos: {
            title: "Young Toddlers",
            age: "13 to 24 months",
          },
          mayores: {
            title: "Older Toddlers",
            age: "2 to 3 years",
          },
          preescolar: {
            title: "Preschool",
            age: "3 to 5 years",
          },
        },
        types: {
          full_time_5days: "Full time 5 days a week",
          full_time_4days: "Full-time 4 days",
        },
        full_time_note: {
          title: "FULL TIME = 5 to 9 hours",
          description:
            "Note: After 9 hours, $15.00 USD will be charged for each additional hour, in addition to the normal rate.",
          approval:
            "This additional time must be approved in advance for the principal.",
        },
        changes_notice:
          "Any change to this price list will be communicated one week before it takes effect.",
      },
      preinscription: {
        title: "Pre-registration Form - Waiting List",
        sections: {
          child: {
            title: "1. Child Information",
            fullName: "Full Name",
            birthDate: "Birth Date",
            gender: "Gender",
            medicalConditions: "Allergies or Medical Conditions",
            genderOptions: {
              male: "Male",
              female: "Female",
              other: "Other",
            },
          },
          parents: {
            title: "2. Parents/Guardians Information",
            guardian: "Parent/Guardian",
            fullName: "Full Name",
            id: "ID Number",
            mobile: "Mobile Phone",
            phone: "Home Phone",
            email: "Email",
            address: "Address",
            occupation: "Occupation",
            schedule: "Work Schedule",
          },
          enrollment: {
            title: "3. Enrollment Information",
            startDate: "Desired Start Date",
            schedule: "Required Schedule",
            scheduleOptions: {
              fullTime: "Full Time",
              partTime: "Part Time",
            },
          },
          documents: {
            title: "4. Required Documents",
            parentId: "Parents ID Copy",
            birthCertificate: "Birth Certificate Copy",
            vaccineCard: "Vaccination Card",
          },
        },
        terms: "I accept the terms and conditions",
        submit: "Submit Application",
        select: "Select",
      },
    },
  },
};

// Inicializar i18n con las configuraciones y las traducciones
i18n.use(initReactI18next).init({
  resources: texts, // Tus traducciones
  lng: "en", // Idioma por defecto
  fallbackLng: "es", // Idioma de respaldo
  interpolation: {
    escapeValue: false, // react ya se encarga de la protección XSS
  },
});

export default i18n;
