import React, { useState } from 'react';
import { MessageSquare } from 'lucide-react';

const FloatingButton = () => {
  const [isAnimating, setIsAnimating] = useState(false); // Corregido aquí

  // URL del chat (reemplaza esta URL con la de tu chat)
  const chatUrl = 'https://ia.news724.online/chatbot/7b1c0cb0-2996-46fe-94a1-d261f9d1eabc';

  const handleClick = () => {
    setIsAnimating(true);
    
    // Abrir nueva pestaña
    window.open(chatUrl, '_blank', 'noopener,noreferrer');
    
    // Resetear la animación
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <button
      onClick={handleClick}
      className={`
        fixed bottom-6 right-6 z-50
        w-14 h-14 md:w-16 md:h-16
        flex items-center justify-center
        rounded-full
        transition-all duration-300 ease-in-out
        bg-blue-500 hover:bg-blue-600
        shadow-lg hover:shadow-xl
        ${isAnimating ? 'scale-90' : 'scale-100'}  {/* Corregido aquí */}
        group
      `}
      aria-label="Abrir chat"
    >
      {/* Círculo de pulso */}
      <div className={`
        absolute inset-0
        rounded-full
        animate-pulse-ring
        bg-blue-400 opacity-20
      `} />

      {/* Icono */}
      <div className="relative transition-transform duration-300 group-hover:scale-110">
        <MessageSquare className="w-7 h-7 md:w-8 md:h-8 text-white" />
      </div>

      {/* Tooltip (opcional) */}
      <div className="
        absolute bottom-full mb-2 
        hidden group-hover:block 
        whitespace-nowrap
        bg-gray-800 text-white 
        text-sm px-3 py-1 
        rounded-lg
        transform -translate-x-1/2 left-1/2
      ">
        Abrir chat
        <div className="
          absolute top-full left-1/2 -translate-x-1/2 
          border-4 border-transparent 
          border-t-gray-800
        "/>
      </div>
    </button>
  );
};

export default FloatingButton;
