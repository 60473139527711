// FormComponents.jsx
import React from 'react';

// Componente Input reutilizable
export const FormInput = ({ label, required = false, ...props }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label} {required && '*'}
    </label>
    <input
      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      {...props}
    />
  </div>
);

// Componente Select reutilizable
export const FormSelect = ({ label, options, required = false, ...props }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label} {required && '*'}
    </label>
    <select
      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      {...props}
    >
      <option value="">Seleccionar</option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

// Componente Section reutilizable
export const FormSection = ({ title, children }) => (
  <section className="space-y-6">
    <h2 className="text-xl font-semibold text-gray-700 border-b pb-2">
      {title}
    </h2>
    {children}
  </section>
);

// Componente Checkbox reutilizable
export const FormCheckbox = ({ label, ...props }) => (
  <div className="flex items-center">
    <input
      type="checkbox"
      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
      {...props}
    />
    <label className="ml-2 text-sm text-gray-700">{label}</label>
  </div>
);

// Componente ParentInfo reutilizable
export const ParentInfoForm = ({ parentNumber, onChange }) => (
  <div className={`space-y-4 mt-8 pt-6 \${parentNumber === 2 ? 'border-t' : ''}`}>
    <h3 className="text-lg font-medium text-gray-700">
      Padre/Tutor {parentNumber}
    </h3>
    <div className="grid md:grid-cols-2 gap-6">
      <FormInput 
        label="Nombre completo"
        type="text"
        name={`parent\${parentNumber}Name`}
        required
        onChange={onChange}
      />
      <FormInput 
        label="DNI/Identificación"
        type="text"
        name={`parent\${parentNumber}Id`}
        required
        onChange={onChange}
      />
      <FormInput 
        label="Teléfono móvil"
        type="tel"
        name={`parent\${parentNumber}Mobile`}
        required
        onChange={onChange}
      />
      <FormInput 
        label="Teléfono fijo"
        type="tel"
        name={`parent\${parentNumber}Phone`}
        onChange={onChange}
      />
      <FormInput 
        label="Email"
        type="email"
        name={`parent\${parentNumber}Email`}
        required
        onChange={onChange}
      />
      <FormInput 
        label="Dirección"
        type="text"
        name={`parent\${parentNumber}Address`}
        required
        onChange={onChange}
      />
      <FormInput 
        label="Ocupación"
        type="text"
        name={`parent\${parentNumber}Occupation`}
        onChange={onChange}
      />
      <FormInput 
        label="Horario laboral"
        type="text"
        name={`parent\${parentNumber}Schedule`}
        onChange={onChange}
      />
    </div>
  </div>
);